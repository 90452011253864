import { useState } from "react";

import { BrowserRouter as Router, Route } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import CartScreen from './screens/CartScreen';
import HomeScreen from './screens/HomeScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import OrderScreen from './screens/OrderScreen';
import PaymentMethodScreen from './screens/PaymentMethodScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import ProductScreen from './screens/ProductScreen';
import ProfileScreen from './screens/ProfileScreen';
import RegisterScreen from './screens/RegisterScreen';
import ShippingAddressScreen from './screens/ShippingAddressScreen';
import SigninScreen from './screens/SigninScreen';


import ContactScreen from './screens/ContactScreen';

import AvailabilityScreen from './screens/AvailabilityScreen';
import AboutScreen from './screens/AboutScreen';
import Navbar from './components/Navbar';
import SideDrawer from './components/SideDrawer';
import Backdrop from './components/Backdrop';
function App() {
  /*
  const cart = useSelector((state) => state.cart);
 
  const { cartItems } = cart;
  */

 

  const [sideToggle, setSideToggle] = useState(false);
 
 
 
 
  
  return (
    <Router>
      
      <Navbar click={() => setSideToggle(true)} />
      <SideDrawer show={sideToggle} click={() => setSideToggle(false)} />
      <Backdrop show={sideToggle} click={() => setSideToggle(false)} />
        <main>
        <Route path="/cart/:id?" component={CartScreen}></Route>
          <Route path="/product/:id" component={ProductScreen}></Route>
          <Route path="/availability" component={AvailabilityScreen}></Route>
          <Route path="/about" component={AboutScreen}></Route>
          <Route path="/signin" component={SigninScreen}></Route>
          <Route path="/contact" component={ContactScreen}></Route>
          <Route path="/register" component={RegisterScreen}></Route>
          <Route path="/shipping" component={ShippingAddressScreen}></Route>
          <Route path="/payment" component={PaymentMethodScreen}></Route>
          <Route path="/placeorder" component={PlaceOrderScreen}></Route>
          <Route path="/order/:id" component={OrderScreen}></Route>
          <Route path="/orderhistory" component={OrderHistoryScreen}></Route>
          <PrivateRoute
            path="/profile"
            component={ProfileScreen}
          ></PrivateRoute>
          <Route path='/arbnb' component={() => { 
     window.location.href = 'https://www.airbnb.com/rooms/52672646?check_in=2022-02-01&check_out=2022-02-03&federated_search_id=969a6520-99cc-470f-b056-8fdb1d0289b3&source_impression_id=p3_1637970205_pi5%2F3zuoK0RAicVI&guests=1&adults=1'; 
     
}}/>

          <Route path="/" component={HomeScreen} exact></Route>
        </main>
      
    </Router>
  );
}
export default App;
