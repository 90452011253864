
import React from 'react';
import Carousel from '../components/Carousel';





export default function HomeScreen() {
  const data = [
    {
      image:
        "images/night.jpg",
      caption: `<div>
        The House
      </div>`,
    },
    {
      image:
        "images/s1.webp",
      caption: "<div>Cosy Living Room with Wood Burning Stove</div>",
    },
    
    {
      image:
        "images/s3.webp",
      caption: "<div>Dining Area with Large Table </div>",
    },
    
    
    
    {
      image:
        "images/s5.webp",
      caption: `<div>
        Kitchen
      </div>`,
    },
    {
      image:
        "images/s6.webp",
      caption: "<div>The Snug</div>",
    },
    {
      image:
        "images/s4.webp",
      caption: "<div>Downstairs Bedroom </div>",
    },
   
    {
      image:
        "images/s7.webp",
      caption: "<div>Hallway</div>",
    },
    
    {
      image:
        "images/s9.webp",
      caption: "<div>Family Bedroom </div>",
    },
    
    {
      image:
        "images/s11.webp",
      caption: "<div>Family Bedroom</div>",
    },
    {
      image:
        "images/s12.webp",
      caption: "<div>Twin Room</div>",
    },
    {
      image:
        "images/s13.webp",
      caption: "<div>Twin Room</div>",
    },
    {
      image:
        "images/s14.webp",
      caption: "<div>Downstairs Shower Room</div>",
    },
    {
      image:
        "images/s15.webp",
      caption: "<div>Upstairs Bathroom</div>",
    },
    {
      image:
        "images/s16.webp",
      caption: "<div>Garden</div>",
    },
    {
      image:
        "images/s17.webp",
      caption: "<div>Garden</div>",
    },
    {
      image:
        "images/s18.webp",
      caption: "<div>Snowy Garden</div>",
    },
    {
      image:
        "images/s19.webp",
      caption: "<div>5 Person Hot Tub</div>",
    },
    
    
    
    
  ];


  return (
    <div>
  
        <div className="row center">
            
        <Carousel
            data={data}
            time={2000}
            width="500px"
            height="500px"
            
            radius="10px"
            slideNumber={true}
           
            captionPosition="bottom"
            automatic={false}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            showNavBtn={true}
            style={{
              textAlign: "center",
              maxWidth: "850px",
              margin: "80px auto",
            }}
          />
         
        </div>
    
    </div>
  );
}

