import React from 'react'

 export default function AboutScreen ()  {
    return (
<div className="contain">
        <div className="row center inside"style={{ backgroundImage: `url(images/night.jpg)`,backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '8px'
        }}>
           
<p>Great for family get togethers or two families enjoying a break together. <br />
     Spend an unforgettable family Christmas or Hogmanay/New Year here.<br /> 
      Nestled in the woods just behind the Rothiemurchs Visitor Centre, <br />
       you have direct access to muliple paths for walking and cycling.<br />
        Aviemore is a very pleasant 15 minute walk away.</p>

        <br />
<p>The house is set in a quiet residential area so strictly no hen/stag parties.</p>
<br />

<p>The house has a cosy sitting room with a woodburning stove and a separate snug for the little ones to chill out and watch TV. <br />
 There is a fully equipped kitchen with an electric AGA with gas hob and a dining area with a large dining table. 
 <br /> Downstairs, there is a bedroom with a king bed and a large shower room.<br />
  Upstairs, a there is a family room with a king bed and a single bed, two twin rooms, a study and a  family bathroom. There is a fully enclosed garden  looking out to the woods. There is  a 5 seater hot tub to relax into after a day of walking, cycling or skiing.
There is a locked bike storage with access to 2 sockets for charging  e-bikes  if required and parking for up to 4 cars at the front of the house.

You will have access to the whole house including the garden shed where you will find sledges, deckchairs and a snow shovel as you never know what the Scottish weather will throw at you.</p>
        </div>
        </div>
       
    )
}
