import React from 'react'

export default function AvailabilityScreen () {
    var link = <a className="tubLink"href='https://www.airbnb.com/rooms/52672646?check_in=2022-02-01&
    check_out=2022-02-03&federated_search_id=969a6520-99cc-470f-b056-8fdb1d0289b3&
    source_impression_id=p3_1637970205_pi5%2F3zuoK0RAicVI&guests=1&adults=1'
    >here</a>;
    return (
        <div className="row center tub" style={{ backgroundImage: `url(images/s19.webp)`,backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '8px'
        }}>
           
<h2 className="tub">If you would like to stay in this beautiful house in the heart of the Scottish Highlands, please click  {link} {''}
   to view our Airbnb listing for availability and booking options.</h2>

        
</div>
    )
}
