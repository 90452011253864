import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { detailsProduct } from '../actions/productActions';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import ReactPlayer from 'react-player';

export default function ProductScreen(props) {
  const dispatch = useDispatch();
  const productId = props.match.params.id;
  
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  useEffect(() => {
    dispatch(detailsProduct(productId));
  }, [dispatch, productId]);
  
 

  return (

    <div>
     {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div>
          
       
          <div className='player-wrapper'>
          <ReactPlayer 
            className='react-player'
          url={product.video} 
    autoplay={false}
    playing={true}
    controls={true}
    width="100%"
    height="100%"
/>
        </div>
            <div className="col-1">
              <ul>
                <li>
                  <h1 className="gradient-text-large" >{product.name}</h1>
                </li>
                <li>
                  
                </li>
                <li><h1>Originally broadcast on  - {product.date}</h1></li>
               
              </ul>
            </div>
            
            </div>
    
        
  
      )}
    </div>
  );
}
