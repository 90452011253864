import React from 'react'

export default function ContactScreen  ()  {
  var link = <a href='mailto:thesquirrelsaviemore@gmail.com'>email</a>;
    return (
   
    
        <div className="row center contact"style={{ backgroundImage: `url(images/tub.jpg)`,backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '8px'
        }}>
        <p>  Please feel free to get in touch via {link}  if you have any questions </p>
        </div>
    
    
    )
}
