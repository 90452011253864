import React from 'react';
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = ({ click }) => {
 
 
  
  

  return (
    <nav className="navbar">
      <div className="navbar__logo">
      <Link to ="/" ><h2>The Squirrels Aviemore</h2></Link>
      </div>

      <ul className="navbar__links">
       
      <li>
          <Link to="/" >
           
              Home
            
          </Link>
        </li> <li>
          <Link to="/about" >
           
              About
            
          </Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/availability">Availablity</Link>
        </li>
      </ul>

      <div className="hamburger__menu" onClick={click}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </nav>
  );
};

export default Navbar;
